<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="COUPON"
        :filter="filter"
        placeholder="Search Coupon Name"
        @sidebar="sidebarFilter"
        @search="searchCouponList"
        :hasDropdown="false"
        routerPath="/coupon/details/0"
      />

      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-tabs>
          <b-tab title="General Coupon" active>
            <CouponListPanel
              :columns="columns"
              :list="items"
              :isBusy="isBusy"
            />
            <Pagination
              :pageOptions="pageOptions"
              :filter="filter"
              :rows="rows"
              @pagination="pagination"
              @handleChangeTake="handleChangeTake"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <SidebarFilter
      ref="sidebarFilter"
      :filter="filter"
      @filterSearch="filterSearch"
    />
  </div>
</template>

<script>
import CouponListPanel from "@/components/coupon/CouponListPanel";
import OtherLoading from "@/components/loading/OtherLoading";
import SidebarFilter from "@/components/coupon/SidebarFilter";
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
export default {
  components: {
    CouponListPanel,
    OtherLoading,
    SidebarFilter,
    HeaderPanel,
    Pagination,
  },
  data() {
    return {
      items: [],
      filter: {
        search: "",
        take: 10,
        page: 1,
        status: null,
        start_date: "0001-01-01",
        end_date: "0001-01-01",
        redeem_type: [],
      },
      listCoupon: [],
      breadcrumb: [
        {
          text: "Privilege Code",
          to: "",
        },
        {
          text: "Privilege Code Detail",
          to: "",
        },
      ],
      isLoading: true,
      rows: 0,
      showing: 1,
      showingTo: 10,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      isBusy: false,
      columns: [
        {
          label: "Image",
          key: "image_url",
          thStyle: { width: "15%" },
          tdClass: "p-3",
        },
        {
          label: "Coupon Name/Description",
          key: "name",
          thStyle: { width: "35%" },
        },
        {
          label: "Redeem Type",
          key: "allow_customer_redeem",
        },
        {
          label: "Discount",
          key: "discount",
        },
        {
          label: "Quantity",
          key: "max_quantity",
        },
        {
          label: "Coupon Code",
          key: "prefix",
        },
        {
          label: "Valid From",
          key: "validFrom",
        },
        {
          label: "Valid To",
          key: "validTo",
        },
        {
          label: "Status",
          key: "status",
        },
      ],
    };
  },
  created() {
    setTimeout(() => {
      this.getCouponList();
    }, 500);
  },
  methods: {
    async getCouponList() {
      this.isBusy = true;
      this.isLoading = false;
      await this.$store.dispatch("getCouponList", this.filter);
      if (!this.$store.state.coupon.modalAlertLogin) {
        this.rows = this.$store.state.coupon.couponList.detail.total_count;
        this.items = this.$store.state.coupon.couponList.detail.detail;
        this.showingTo =
          this.items?.length >= 10 ? this.showingTo : this.items.length;
        this.isBusy = false;
      } else {
        this.errorAlert();
      }
    },
    searchCouponList() {
      this.getCouponList();
    },
    hideModal() {
      window.location.href = "/";
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.getCouponList();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.showingTo = this.filter.take;
      this.getCouponList();
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show(true);
    },
    filterSearch(value) {
      this.filter = value;
      this.getCouponList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.alert-error {
  text-align: center;
  padding: 20px;
  color: #000;
}
::v-deep .custom-select {
  @media (max-width: 767px) {
    width: 100%;
  }
}
::v-deep .header-tablepage {
  font-weight: 600;
}
.btn-create {
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  margin: 15px 0;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .nav.nav-tabs {
  background-color: white;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
::v-deep .nav-tabs {
  border: none;
}
::v-deep .nav-tabs .nav-link {
  border: none;
  border-right: 1px solid #cfcfcf;
}
::v-deep .nav-tabs .nav-link {
  border-color: #d8dbe0 !important;
  color: #717171 !important;
}
::v-deep .nameOfTheClass {
  max-width: 250px !important;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
::v-deep .width-td-img {
  width: 200px !important;
}
</style>
